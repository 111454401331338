import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Loading from '../../../../Common/Loading';
import { Column } from '../../../../Common/styled/Groups';
import { GreyLabel } from '../../../../Common/styled/GreyLabel';
import { ExtraBold } from '../../../../Common/styled/Text';
import { Margin } from '../../../../Common/styled/Margins';
import { GET_HOSPITAL_GROUPS_FOR_PREVIEW } from '../../../../../graphql/queries';
import { DELETE_USER_GROUP } from '../../../../../graphql/mutations';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 600,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const DeleteGroupDialog = ({ open, onClose, group = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const uuid = useSelector((state) => state.hospital.uuid);

  const [deleteUserGroup, { loading }] = useMutation(DELETE_USER_GROUP, {
    onCompleted: () => {
      onClose();
      toast(t('group.removed'), { className: 'toast-success' });
    },
    onError: () => {
      toast(t('there.was.an.error.removing.the.group'), { className: 'toast-error' });
    },
    refetchQueries: [{ query: GET_HOSPITAL_GROUPS_FOR_PREVIEW, variables: { uuid } }],
    awaitRefetchQueries: true,
  });

  const handleDelete = (event) => {
    event.stopPropagation();
    deleteUserGroup({ variables: { userGroupUuid: group.uuid } });
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('loading.removing.hospital.group')}</DialogTitle>
      {loading ? (
        <Loading />
      ) : (
        <>
          <DialogContent>
            <DialogContentText>
              {t('group.delete.confirmation.message')}
            </DialogContentText>
            <Margin mx-0 mt-5>
              <Column>
                <GreyLabel>{`${t('team.member.name')}:`}</GreyLabel>
                <ExtraBold>{group.name}</ExtraBold>
              </Column>
            </Margin>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              className={classes.button}
              color="primary"
              onClick={onClose}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              color="primary"
              onClick={handleDelete}
            >
              {t('delete')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
