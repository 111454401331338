import React, { useState } from 'react';

import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import {
  AddBoxOutlined,
  ArrowDropDown,
  ArrowDropUp,
  DeleteTwoTone,
  Edit,
  GroupWork,
  Settings,
  Sort } from '@material-ui/icons';

import { Container, Menu, MenuItem } from '@material-ui/core';
import { GET_HOSPITAL_GROUPS_FOR_PREVIEW } from '../../../../graphql/queries';
import { isEmpty } from '../../../../utils/ObjectUtils';
import { Navbar } from '../../../Navbar/styled/NavbarStyles';
import { PageListContent } from '../../../Common/styled/PageContent';
import { CardWrapperUI } from '../../../Common/styled/CardWrapperUI';
import Loading from '../../../Common/Loading';
import { SectionBar } from '../../../../componentsUI/SectionBar';
import TableCollapse from '../../../../componentsUI/TableCollapse';
import { NoMembers } from '../../../Common/GroupCard/GroupMemberCard';
import { NewGroupDialog } from './modal/NewGroupDialog';
import { DeleteGroupDialog } from './modal/DeleteGroupDialog';
import { EditGroupDialog } from './modal/EditGroupDialog';
import { cannotI } from '../../../../casl/Can';

const fieldNames = [
  { label: 'name', id: 'name', width: 180, field: 'NAME', direction: 'ASC' },
  { label: 'members', id: 'groupMembers.length', width: 60 },
];

export const Groups = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selected, setSelected] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const uuid = useSelector((state) => state.hospital.uuid);

  const sortData = {
    default: { field: 'NAME', direction: 'ASC' },
    fields: [{ key: 'NAME', text: 'Name' }],
  };
  const [sortByField, setSortingField] = useState(sortData.default.field);
  const [sortByDirection, setSortingDirection] = useState(sortData.default.direction);

  const { loading, data, refetch } = useQuery(
    GET_HOSPITAL_GROUPS_FOR_PREVIEW, {
      variables: {
        uuid,
      },
    },
  );

  const hospital = data && data.hospital;
  const userGroups = hospital && hospital.userGroups;

  const reverseDirection = () => (sortByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === sortByField) {
        setSortingDirection(reverseDirection());
      } else {
        setSortingDirection(value.direction);
      }

      setSortingField(value.field);
      setAnchorEl();
      setSelected([]);
    }
  };

  const getTitle = (group) => (
    `${group.name}`
  );

  const getSubTitle = (group) => (
    `${group.groupMembers.length} ${t('members')}`
  );

  const handleCloseNew = () => {
    setShowNewModal(false);
    refetch();
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    refetch();
  };

  const handleCloseDelete = () => {
    setSelected([]);
    setShowDeleteModal(false);
    refetch();
  };

  const handleOpenSelected = (event) => {
    event.stopPropagation();
    const groupUuid = userGroups[selected[0]].uuid;
    navigate(`hospital/settings/group/edit/${groupUuid}`);
  };

  const handleOpenLink = (event, index) => {
    event.stopPropagation();
    setSelected([index]);
    const groupUuid = userGroups[index].uuid;
    navigate(`hospital/settings/group/edit/${groupUuid}`);
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const tableRowConfigureButton = {
    Icon: Settings,
    tooltip: 'manage.group.members',
    disabled: cannotI('manage', hospital),
  };

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDown className={className} /> : <ArrowDropUp className={className} />
  );

  const buttons = [
    { name: 'add.group', icon: AddBoxOutlined, I: 'manage', data: hospital, handleClick: () => setShowNewModal(true), disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'edit.group.name', icon: Edit, I: 'manage', data: hospital, handleClick: () => setShowEditModal(true), disabled: selected.length === 0 },
    { name: 'manage.group.members', icon: GroupWork, I: 'manage', data: hospital, handleClick: handleOpenSelected, disabled: selected.length === 0 },
    { name: 'delete.group', icon: DeleteTwoTone, I: 'manage', data: hospital, handleClick: () => setShowDeleteModal(true), disabled: selected.length === 0 },
    { name: 'divider2', type: 'divider' },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder, disabled: false },
  ];

  const group = selected && userGroups && userGroups[selected[0]];

  return (
    <>
      <Navbar>
        <NewGroupDialog open={showNewModal} onClose={handleCloseNew} />
        <EditGroupDialog open={showEditModal} onClose={handleCloseEdit} group={group} />
        <DeleteGroupDialog open={showDeleteModal} onClose={handleCloseDelete} group={group} />

        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === sortByField) && <OrderIcon direction={sortByDirection} />}
            </MenuItem>
          ))}
        </Menu>

        <SectionBar title="hospital.settings.groups.manage" items={buttons} />
      </Navbar>

      <Container maxWidth="lg">
        <PageListContent>
          {loading && isEmpty(data) ? <Loading /> : (
            <CardWrapperUI>
              {userGroups ? (
                <TableCollapse
                  fieldNames={fieldNames}
                  items={userGroups}
                  GetTitle={getTitle}
                  GetSubtitle={getSubTitle}
                  selected={selected}
                  setSelected={setSelected}
                  ActionButtons={tableRowConfigureButton}
                  handleGoto={handleOpenLink}
                  order={{ field: sortByField, direction: sortByDirection }}
                  handleOrder={handleOrderBy}
                />
              ) : <NoMembers />}
            </CardWrapperUI>
          )}
        </PageListContent>
      </Container>
    </>
  );
};
