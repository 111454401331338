import React from 'react';

import Layout from '../../../../components/MenuLayout';
import { Groups } from '../../../../components/Hospital/Management/Group/Groups';

export default () => (
  <Layout type="settings">
    <Groups />
  </Layout>
);
